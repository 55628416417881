<template>
    <div class="rates" data-component="Rates" id="Rates">
        <div class="wrapper">
            <h1 class="title">{{ $ml.get("tariffs") }}</h1>

            <table class="rates__table" border="0">
                <tr>
                    <th rowspan="2" class="rates__table__service-name-header">
                        {{ $ml.get("information_from_registers_and_open_data") }}
                    </th>
                    <th colspan="3" class="rates__table__service-pricing-header">
                        {{ $ml.get("number_of_land_plots_in_one_request") }}
                    </th>
                </tr>
                <tr>
                    <td class="rates__table__heading-quantity-relative-to-price">{{ $ml.get("up_to_1000_pcs") }}</td>
                    <td class="rates__table__heading-quantity-relative-to-price">{{ $ml.get("up_to_10000_pcs") }}</td>
                    <td class="rates__table__heading-quantity-relative-to-price">{{
                            $ml.get("more_than_10000_pcs")
                        }}
                    </td>
                </tr>
                <tr
                    v-for="(price, index) in pricing"
                    :key="`rate_item__${index}`"
                >
                    <td class="rates__table__service-name">{{ $ml.get(price.name) }}</td>
                    <td class="rates__table__service-pricing">{{ getPrice(price.price_before_1000) }}</td>
                    <td class="rates__table__service-pricing">{{ getPrice(price.price_before_10000) }}</td>
                    <td class="rates__table__service-pricing">{{ getPrice(price.price_after_10000) }}</td>
                </tr>

                <tr class="last-item">
                    <td class="rates__table__service-name" style="font-size: 0;">1</td>
                    <td class="rates__table__service-pricing"/>
                    <td class="rates__table__service-pricing"/>
                    <td class="rates__table__service-pricing"/>
                </tr>
            </table>
        </div>



            <table class="rates__mobile-table" v-for="(price_type, index) in price_types" :key="`rates__mobile-table__${index}`">
                <tr>
                    <th colspan="2" class="rates__mobile-table__header">
                        {{ $ml.get("number_of_land_plots_in_one_request") }}
                    </th>
                </tr>
                <tr>
                    <td colspan="2" class="rates__mobile-table__amount">
                        {{ price_type.title }}
                    </td>
                </tr>
                <tr>
                    <td class="rates__mobile-table__subheader-price-name">
                        {{ $ml.get("information_from_registers_and_open_data_capitalize") }}
                    </td>
                    <td class="rates__mobile-table__subheader-price">
                        {{ $ml.get("price") }}
                    </td>
                </tr>

                <tr v-for="(price, price_index) in pricing" :key="`rates__mobile-table_tr__${price_index}`">
                    <td class="rates__mobile-table__price-name">
                      {{ $ml.get(price.name) }}
                    </td>
                    <td class="rates__mobile-table__price">
                        {{ price[price_type.type] }}
                    </td>
                </tr>
            </table>
    </div>
</template>

<script>
export default {
    name: "Rates",
    data() {
        return {
            price_types: [
                {
                    title: this.$ml.get("up_to_1000_pcs"),
                    type: "price_before_1000",
                },
                {
                    title: this.$ml.get("up_to_10000_pcs"),
                    type: "price_before_10000",
                },
                {
                    title: this.$ml.get("more_than_10000_pcs_2"),
                    type: "price_after_10000",
                },
            ],

            pricing: [
                {
                    name: "DZK",
                    price_before_1000: "2",
                    price_before_10000: "2",
                    price_after_10000: "2",
                },
                {
                    name: "DRRP",
                    price_before_1000: "6",
                    price_before_10000: "5",
                    price_after_10000: "4",
                },
                {
                    name: "NGO",
                    price_before_1000: "2",
                    price_before_10000: "2",
                    price_after_10000: "2",
                },
                {
                    name: "plot_vector",
                    price_before_1000: "2",
                    price_before_10000: "2",
                    price_after_10000: "2",
                },
                {
                    name: "complete_analytics",
                    price_before_1000: "10",
                    price_before_10000: "9",
                    price_after_10000: "8",
                },
            ]
        };
    },
    methods: {

      getPrice(price){
        return this.$ml.with(price).get("price_tag")
      }

    }
}
</script>
